
import { computed, defineComponent, onMounted, PropType, Ref, ref } from 'vue';
import { PatientInfoItem } from '@/models/PatientInfo';
import HeadingComponent from '../HeadingComponent.vue';

export default defineComponent({
  components: { HeadingComponent },
  emits: ['update:modelValue'],
  props: {
    modelValue: null,
    editMode: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    options: {
      type: Array as PropType<PatientInfoItem[]>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const editModeReactive = computed(() => props.editMode);
    const mutableItems: Ref<PatientInfoItem[]> = ref([]);
    const emptyItem = {
      id: '',
      text: '',
      value: false,
    };
    const selectedRadioOption: Ref<PatientInfoItem> = ref(emptyItem);

    const showItem = (item: PatientInfoItem) => {
      return editModeReactive.value === false && isItemSelected(item);
    };

    const isItemSelected = (item: PatientInfoItem) => {
      return selectedRadioOption.value === item;
    };

    const setInitialValues = () => {
      mutableItems.value = [...props.options];
      selectedRadioOption.value = mutableItems.value.find((i) => i.value === true) || emptyItem;
    };

    onMounted(() => setInitialValues());

    const onValueChanged = () => {
      emit('update:modelValue', selectedRadioOption.value.id);
    };

    return {
      editModeReactive,
      mutableItems,
      selectedRadioOption,
      showItem,
      onValueChanged,
      setInitialValues,
    };
  },
});

import { PatientInfoItem } from '@/models/PatientInfo';
import { AnswersCgmInitial } from '@/models/Survey';
import { useI18n } from 'vue-i18n';

const surveysHelper = {
  createOptionsObject(keyPrefix: string, optionsObject: any, defaultValue: string | undefined) {
    const { t } = useI18n();
    return Object.keys(optionsObject).map((k) => ({
      id: k,
      text: t(`${keyPrefix}.${k}`),
      value: defaultValue !== undefined && defaultValue === k,
    }));
  },

  generateYesNoOptions(
    propName: keyof AnswersCgmInitial,
    answers: AnswersCgmInitial | undefined,
    t: (key: string) => string,
  ): PatientInfoItem[] {
    return [
      {
        id: 'true',
        text: t(`common.yes`),
        value: answers !== undefined && answers[propName] === 'true',
      },
      {
        id: 'false',
        text: t(`common.no`),
        value: answers !== undefined && answers[propName] === 'false',
      },
    ];
  },
};

export default surveysHelper;
